<script>
import feather from 'feather-icons';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'CartButton',
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	computed: {
		...mapGetters({
			cartProductsCount: 'cart/cartProductsCount',
		})
	},
	methods: {
		...mapActions({
			toggleCartSlider: 'cart/toggleCartSlider',
		})
	}
};
</script>

<template>
	<button
		@click="toggleCartSlider()"
		type="button"
		class="focus:outline-none flex justify-center items-center"
		aria-label="Cart"
	>
		<i data-feather="shopping-cart"></i>
		<p class="font-sans ml-1">
			{{ cartProductsCount }}
		</p>
	</button>
</template>