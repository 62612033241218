import { currencyPrice } from '../assets/js/helpers'

const getDefaultState = () => {
    return {
        products: [],
        data: {
            email: null,
            first_name: null,
            last_name: null,
            selectedPayment: 'Dotpay',
        },
        promocode: null,
        // Slider
        cartSliderOpen: false,
    }
}

const state = getDefaultState()

const getters = {
    cart: state => state,
    cartProducts: (state, getters, rootState, rootGetters) => {
        return state.products.map(prodId => rootGetters.products.find(p => p.id == prodId))
    },
    cartData: state => state.data,
    cartTotalPrice: (state, getters, rootState, rootGetters) => {
        let price = getters.cartProducts.reduce((acc, p) => acc + currencyPrice(p, 'price', rootGetters.currency), 0)

        if (state.promocode) {
            price *= (1 - state.promocode.percentage/100.0)
        }

        return Math.round(price * 100) / 100.0
    },
    cartProductsCount: state => state.products.length,
    canCreateOrder: state => !Object.values(state.data).some(v => v === null),
    orderData: state => ({
        data: state.data,
        products: state.products,
        promocode: state.promocode,
    }),
    promocode: state => state.promocode,
    // Slider
    cartSliderOpen: state => state.cartSliderOpen,
}

const mutations = {
    updateCart(state, payload) {
        state = {...state, ...payload }
    },
    setCartData(state, payload) {
        state.data[payload.field] = payload.value
    },
    toggleProductInCart(state, payload) {
        const index = state.products.indexOf(payload)
        if (index >= 0) {
            state.products.splice(index, 1);
        } else {
            state.products.push(payload)
        }
    },
    resetOrder(state) {
        Object.assign(state, getDefaultState())
    },
    setPromocode(state, payload) {
        state.promocode = payload
    },
    // Slider
    openCartSlider(state) {
        if (state.products.length <= 0) return

        document
            .getElementsByTagName('html')[0]
            .classList.add('overflow-y-hidden');
        state.cartSliderOpen = true
    },
    closeCartSlider(state) {
        document
            .getElementsByTagName('html')[0]
            .classList.remove('overflow-y-hidden');
        state.cartSliderOpen = false
    },
}

const actions = {
    setCartData({ commit }, payload) {
        commit('setCartData', payload)
    },
    toggleProductInCart({ commit, getters }, payload) {
        commit('toggleProductInCart', payload)
        if (getters.cartProductsCount <= 0) commit('closeCartSlider')
    },
    resetOrder({ commit }) {
        commit('resetOrder')
    },
    setPromocode({ commit }, payload) {
        commit('setPromocode', payload)
    },
    // Slider
    toggleCartSlider({ commit, getters }) {
        commit(getters.cartSliderOpen ? 'closeCartSlider' : 'openCartSlider')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}