<script>
import AppBanner from '@/components/shared/AppBanner';
import ProductsGrid from '../components/products/ProductsGrid.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		ProductsGrid,
	},
};
</script>

<template>
	<div class="container mx-auto">
		<AppBanner class="mb-5 sm:mb-8" />
		<ProductsGrid />
	</div>
</template>

<style scoped></style>
