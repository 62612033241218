<script>
import feather from 'feather-icons';
import Button from './reusable/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import { registration } from '../api/api'
import { duplicateErrors } from '../assets/js/errors'

export default {
	components: { Button },
	data() {
		return {
			modalType: 'login',
			email: '',
			password: '',
			password2: '',
			terms: false,
			reqSend: false,
			resSuccess: false,
		};
	},
	methods: {
		...mapActions({
			login: 'user/login',
			showAuthModal: 'user/showAuthModal',
		}),
		submitForm() {
			this.reqSend = true

			const authFunc = this.modalType == 'login' ? this.login : registration
			const data = this.modalType == 'login' ? {
				email: this.email,
				password: this.password,
			} : {
				email: this.email,
				password: this.password,
				password_confirmation: this.password2,
				terms: this.terms,
			}
			authFunc(data).then(() => {
				this.reqSend = false
				this.resSuccess = true
				this.$toast.success(this.$t(this.modalType == 'login' ? 'messages.login' : 'messages.signup'))
				this.showAuthModal()
				this.resetData()
			}).catch(error => {
				this.reqSend = false
				this.resSuccess = false
				if (error.response) {
					if (this.modalType == 'login') {
						this.$toast.error(duplicateErrors(error.response.data.errors).join('<br/>'))
					} else {
						const errors = []
						Object.values(error.response.data.errors).forEach(err => {
							err.forEach(e => {
								errors.push(this.$t(e))
							})
						})
						this.$toast.error(errors.join('<br/>'))
					}
				} else {
					this.$toast.error(this.$t('messages.fillAllFields'))
				}
			})
		},
		resetData() {
			this.email = ''
			this.password = ''
			this.password2 = ''
			this.terms = false
		},
		toggleModal() {
			this.modalType = this.modalType == 'login' ? 'signup' : 'login'
		},
	},
	computed: {
		...mapGetters({
			authModal: 'user/authModal',
		}),
	},
	mounted() {
		this.modalType = 'login'
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<transition name="fade">
		<div v-show="authModal" class="font-general-regular fixed inset-0 z-30">
			<!-- Modal body background as backdrop -->
			<div
				v-show="authModal"
				@click="showAuthModal()"
				class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"
			></div>
			<!-- Modal content -->
			<main
				class="flex flex-col items-center justify-center h-full w-full"
			>
				<transition name="fade-up-down">
					<div
						v-show="authModal"
						class="modal-wrapper flex items-center z-30"
					>
						<div
							class="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-primary-side max-h-screen shadow-lg flex-row rounded-lg relative"
						>
							<div
								class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-side"
							>
								<h5
									class=" text-primary-main text-xl"
								>
									<template v-if="modalType == 'login'">{{ $t('nav.login') }}</template>
									<template v-else>{{ $t('nav.signup') }}</template>
								</h5>
								<button
									class="px-4 text-primary-main"
									@click="showAuthModal()"
								>
									<i data-feather="x"></i>
								</button>
							</div>
							<div class="modal-body p-5 w-full h-full">
								<div class="max-w-xl m-4 text-left">
									<div class="mt-0">
										<input
											v-model="email"
											class="w-full px-5 py-2 border-1 border-gray-200 rounded-md text-md"
											id="email"
											name="email"
											type="text"
											required=""
											:placeholder="$t('forms.email')"
											aria-label="Email"
										/>
									</div>
									<div class="mt-6">
										<input
											v-model="password"
											class="w-full px-5 py-2 border-1 border-gray-200 rounded-md text-md"
											id="password"
											name="password"
											type="password"
											required=""
											:placeholder="$t('forms.password')"
											aria-label="Password"
										/>
									</div>
									<template v-if="modalType == 'signup'">
										<div class="mt-6">
											<input
												v-model="password2"
												class="w-full px-5 py-2 border-1 border-gray-200 rounded-md text-md"
												id="password2"
												name="password2"
												type="password"
												required=""
												:placeholder="$t('forms.password2')"
												aria-label="Password 2"
											/>
										</div>

										<div class="mt-6">
											<label for="terms" class="text-sm font-medium text-secondary-main flex content-center">
												<input
													v-model="terms"
													class="border-1 border-gray-200 rounded-md text-md mr-2"
													id="terms"
													name="terms"
													type="checkbox"
													required=""
													:placeholder="$t('forms.terms')"
													aria-label="Terms"
												/>
												{{ $t('forms.terms') }}
											</label>
										</div>
									</template>
									<div class="mt-6 pb-4 sm:pb-1 flex justify-between">
										<button
											@click="submitForm"
											class="px-4
											sm:px-6
											py-2
											sm:py-2.5
											text-white
											bg-red-600
											hover:bg-red-700
											rounded-md
											focus:ring-1 focus:ring-red-900 duration-500"
										>
											{{ modalType === 'login' ? $t('nav.login') : $t('nav.signup') }}
										</button>
										<Button
											:title="$t('close')"
											class="px-4
											sm:px-6
											py-2
											sm:py-2.5
											text-white
											bg-gray-600
											hover:bg-ternary-main
											rounded-md
											focus:ring-1 focus:ring-red-900 duration-500
											ml-2"
											@click="showAuthModal()"
											aria-label="Close Modal"
										/>
									</div>
									<div class="mt-6 text-center">
										<a
											href="#"
											class="text-sm hover:underline cursor-pointer text-primary-main hover:text-red-600"
											@click.prevent="toggleModal"
											aria-label="Toggle Modal"
										>
											<template v-if="modalType == 'login'">{{ $t('loginModal.toSignup') }}</template>
											<template v-else>{{ $t('loginModal.toLogin') }}</template>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</main>
		</div>
	</transition>
</template>

<style scoped>
.modal-body {
	max-height: 500px;
}
.bg-gray-800-opacity {
	background-color: #2d374850;
}
@media screen and (max-width: 768px) {
	.modal-body {
		max-height: 400px;
	}
}
.fade-up-down-enter-active {
	transition: all 0.3s ease;
}
.fade-up-down-leave-active {
	transition: all 0.3s ease;
}
.fade-up-down-enter {
	transform: translateY(10%);
	opacity: 0;
}
.fade-up-down-leave-to {
	transform: translateY(10%);
	opacity: 0;
}

.fade-enter-active {
	-webkit-transition: opacity 2s;
	transition: opacity 0.3s;
}
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
