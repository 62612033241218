export function currencyPrice(obj, field, currency) {
    return obj[`${field}_${currency}`] || obj[`${field}_pln`] || 0
}

export function currencySign(currency) {
    return currency === "eur" ? "€" : 'zł'
}

export function formatLogoTitle(title) {
    return title.split(' ').map(w => '<span>' + w + '</span>').join(' ')
}