import Admin from '../views/Admin.vue';

export default {
    path: '/adminpanel',
    component: Admin,
    meta: {
        title: 'cTradingTools - Admin',
    },
    children: [
        {
            path: '',
            name: 'Admin',
            component: () =>
                import(/* webpackChunkName: "admin" */ '../components/admin/AdminMenu.vue'),
            meta: { onlyAdmin: true }
        },
        // PRODUCTS
        {
            path: 'products/new',
            name: 'NewProduct',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductForm.vue'),
            meta: { 
                onlyAdmin: true,
                newProduct: true,
            }
        },
        {
            path: 'products/:id',
            name: 'UpdateProduct',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductForm.vue'),
            meta: { 
                onlyAdmin: true,
                newProduct: false,
            }
        },
        {
            path: 'products',
            name: 'ProductsList',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductsList.vue'),
            meta: { onlyAdmin: true }
        },
        {
            path: 'products/:id/images',
            name: 'ProductsImages',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductImages.vue'),
            meta: { onlyAdmin: true }
        },
        {
            path: 'products/:id/packages',
            name: 'ProductsPackages',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductPackages.vue'),
            meta: { onlyAdmin: true }
        },
        {
            path: 'products/:id/instructions',
            name: 'ProductsInstructions',
            component: () =>
                import(/* webpackChunkName: "admin-products" */ '../components/admin/products/ProductInstructions.vue'),
            meta: { onlyAdmin: true }
        },
        // USERS
        {
            path: 'users',
            name: 'UsersList',
            component: () =>
                import(/* webpackChunkName: "admin-users" */ '../components/admin/users/UsersList.vue'),
            meta: { onlyAdmin: true }
        },
        {
            path: 'user/:id',
            name: 'ShowUser',
            component: () =>
                import(/* webpackChunkName: "admin-users" */ '../components/users/ShowUser.vue'),
            meta: { onlyAdmin: true }
        },
        // PAGES
        {
            path: 'pages/new',
            name: 'NewPage',
            component: () =>
                import(/* webpackChunkName: "admin-pages" */ '../components/admin/pages/PageForm.vue'),
            meta: { 
                onlyAdmin: true,
                newPage: true,
            }
        },
        {
            path: 'pages/:id',
            name: 'UpdatePage',
            component: () =>
                import(/* webpackChunkName: "admin-pages" */ '../components/admin/pages/PageForm.vue'),
            meta: { 
                onlyAdmin: true,
                newProduct: false,
            }
        },
        {
            path: 'pages',
            name: 'PagesList',
            component: () =>
                import(/* webpackChunkName: "admin-pages" */ '../components/admin/pages/PagesList.vue'),
            meta: { onlyAdmin: true }
        },
        // PROMOCODES
        {
            path: 'promocodes/new',
            name: 'NewPromocode',
            component: () =>
                import(/* webpackChunkName: "admin-promocodes" */ '../components/admin/promocodes/PromocodeForm.vue'),
            meta: { 
                onlyAdmin: true,
                newPromocode: true,
            }
        },
        {
            path: 'promocodes',
            name: 'PromocodesList',
            component: () =>
                import(/* webpackChunkName: "admin-promocodes" */ '../components/admin/promocodes/PromocodesList.vue'),
            meta: { onlyAdmin: true }
        },
        // Payments
        {
            path: 'payments',
            name: 'PaymentsList',
            component: () =>
                import(/* webpackChunkName: "admin-payments" */ '../components/admin/payments/PaymentsList.vue'),
            meta: { onlyAdmin: true }
        },
    ]
}