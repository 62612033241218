// import { login, logout, updateUserData, validateToken } from '../api/api'
import { login, logout, validateToken } from '../api/api'
import router from '../router'

const state = {
    authModal: false,
    user: null
}

const getters = {
    authModal: state => state.authModal,
    currentUser: state => state.user,
}

const mutations = {
    showAuthModal(state) {
        if (state.currentUser) return

        if (state.authModal) {
            // Stop screen scrolling
            document
                .getElementsByTagName('html')[0]
                .classList.remove('overflow-y-hidden');
                state.authModal = false;
        } else {
            document
                .getElementsByTagName('html')[0]
                .classList.add('overflow-y-hidden');
                state.authModal = true;
        }
    },
    login(state, payload) {
        state.user = payload
    },
    logout(state) {
        state.user = null
    },
    // updateUser(state, payload) {
    //     state.user = {...state.user, ...payload }
    // }
}

const actions = {
    showAuthModal({ commit }) {
        commit('showAuthModal')
    },
    login({ commit }, payload) {
        return login(payload)
            .then(res => {
                commit('login', res.data.data)
            })
    },
    logout({ commit }) {
        return logout()
            .then(() => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('client')
                localStorage.removeItem('tokenType')
                localStorage.removeItem('uid')

                commit('cart/resetOrder', null, { root: true })

                router.push('/').catch(() => {});

                commit('logout')
            })
            .catch(() => {
                window.location.reload()
            })
    },
    // updateData({ commit }, payload) {
    //     return updateUserData(payload)
    //         .then(res => {
    //             commit('login', res.data.data)
    //         })
    // },
    validateToken({ commit, dispatch }) {
        return validateToken()
            .then(res => {
                commit('login', res.data.data.user)
                dispatch('loadPages', res.data.data.pages, { root: true })
                dispatch('pageLoad', null, { root: true })
            })
            .catch(error => {
                dispatch('loadPages', error.response.data.data.pages, { root: true })
                dispatch('pageLoad', null, { root: true })
            })
    },
    // setData({ commit }, payload) {
    //     commit('updateUser', payload)
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}