<script>
import feather from 'feather-icons';
import { ref } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import { mapActions, mapGetters } from 'vuex';
import { updateUserData } from '../api/api'

export default {
	setup() {
		const cookies = useCookies()

		const availableCurrencies = ['pln', 'eur']
		const currencyOpen = ref(false)
		const onSetCurrencyCookie = (currency) => {
			currencyOpen.value = false
			cookies.set('currency', currency)
		}
		return { currencyOpen, onSetCurrencyCookie, availableCurrencies, cookies }
	},
	name: 'CurrencyButton',
	computed: {
		...mapGetters({
			currency: 'currency',
			currentUser: 'user/currentUser',
		}),
	},
	methods: {
		...mapActions({
			setCurrency: 'setCurrency',
		}),
		onSetCurrency(currency) {
			this.setCurrency(currency)
			this.onSetCurrencyCookie(currency)
			if (this.currentUser && this.currentUser.currency !== currency) {
				updateUserData(this.currentUser.id, {
					currency: currency
				})
			}
		}
	},
	watch: {
		currentUser(user, oldUser) {
			if (oldUser === null && user !== null) {
				this.setCurrency(user.currency)
			}
		}
	},
	mounted() {
		feather.replace();

		if (this.availableCurrencies.includes(this.cookies.get('currency'))) {
			this.onSetCurrency(this.cookies.get('currency'))
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="relative inline-block text-left mr-2 sm:mr-0">
		<div>
			<button
				type="button"
				class="focus:outline-none flex justify-center items-center"
				@click="currencyOpen = !currencyOpen"
				id="currency-button" 
				aria-expanded="true" 
				aria-haspopup="true">
				<p class="font-sans">
					{{ currency.toUpperCase() }}
				</p>
			</button>
		</div>

		<div
			v-show="currencyOpen"
			@mouseleave="currencyOpen = false"
			class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-primary-side shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="currency-button"
			tabindex="-1"
		>
			<div class="py-1" role="none">
				<button
					v-for="currency in availableCurrencies"
					:key="currency"
					@click="onSetCurrency(currency)"
					class="text-secondary-main block w-full px-4 py-2 text-left text-sm"
					role="menuitem"
					tabindex="-1"
					id="menu-item-0"
				>
					{{ currency.toUpperCase() }}
				</button>
			</div>
		</div>
	</div>
</template>