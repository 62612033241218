import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop'
import { i18n, defaultLocale } from './i18n'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Toaster from '@meforma/vue-toaster'
import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";

const feather = require('feather-icons');
feather.replace();

export const defaultReqInterceptors = config => {
    config.headers['access-token'] = localStorage.getItem('accessToken')
    config.headers['client'] = localStorage.getItem('client')
    config.headers['token-type'] = localStorage.getItem('tokenType')
    config.headers['uid'] = localStorage.getItem('uid')
    config.headers['lang'] = localStorage.getItem('lang') || defaultLocale

    return config
}

export const defaultResInterceptors = response => {
    const accessToken = response.headers['access-token']
    const client = response.headers['client']
    const tokenType = response.headers['token-type']
    const uid = response.headers['uid']

    if (accessToken && client && tokenType && uid) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('client', client)
        localStorage.setItem('tokenType', tokenType)
        localStorage.setItem('uid', uid)
    }

    return response
}

export const defaultResInterceptorsError = error => {
    if (!error.response) {
        store.dispatch('serverOffline')
        router.push('/')
        return
    }

    if (error.response.statusText === 'Forbidden') {
        router.app.$toast.error(router.app.$t('messages.accessDenied'))

        store.getters['user/currentUser'] && store.getters['user/currentUser'].is_admin ?
            router.push({ name: 'Admin' }) :
            router.push('/')
        return
    }

    if (error.response.statusText === 'Locked') {
        router.app.$toast.error(router.app.$t('messages.banned'))

        localStorage.removeItem('accessToken')
        localStorage.removeItem('client')
        localStorage.removeItem('tokenType')
        localStorage.removeItem('uid')

        if (store.getters['user/currentUser']) {
            store.dispatch('user/logout')
        } else {
            router.push('/')
        }

        return
    }

    return Promise.reject(error)
}

createApp(App)
	.use(router)
	.use(store)
	.use(BackToTop)
	.use(i18n)
    .use(Toaster, {
        position: 'top-right',
        duration: 2000,
    })
    .use(JsonViewer)
    .component('QuillEditor', QuillEditor)
	.mount('#app');
