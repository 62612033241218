<script>
export default {
	name: 'Home',
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<div class="w-full md:w-1/2 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-main"
			>
				<span class="text-red-600">c</span>Trading<span class="text-red-600">Tools</span> – {{ $t('banner.header') }}
			</h1>
			<div
				class="font-general-medium mt-5 text-base sm:text-large xl:text-xl text-center sm:text-left leading-none text-primary-main"
				v-html="$t('banner.content')"
			/>
		</div>

		<div class="w-full md:w-1/2 text-right float-right flex justify-center md:justify-end md:ml-10 mt-10 md:mt-0">
			<img
				src="@/assets/images/banner.png"
				alt="Trading"
				width="500"
			/>
		</div>
	</section>
</template>

<style scoped></style>
