<script>
import feather from 'feather-icons';
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCookies } from '@vueuse/integrations/useCookies'
import { mapActions, mapGetters } from 'vuex';
import { updateUserData } from '../api/api'

export default {
	setup() {
		const { locale, availableLocales } = useI18n({ useScope: 'global' })
		const cookies = useCookies()

		const langOpen = ref(false)
		const setLang = (lang) => {
			locale.value = lang
			langOpen.value = false
			cookies.set('lang', lang)
		}
		return { locale, langOpen, setLang, availableLocales, cookies }
	},
	name: 'LangButton',
	methods: {
		...mapActions({
			setCurrency: 'setCurrency',
		}),
		onSetLang(lang) {
			this.setLang(lang)
			this.changeCuurencyWhenNotSet(lang)
			if (this.currentUser && this.currentUser.lang !== lang) {
				updateUserData(this.currentUser.id, {
					lang: lang
				})
			}
		},
		changeCuurencyWhenNotSet(lang) {
			if (this.cookies.get('currency') === undefined) {
				this.setCurrency(lang === 'pl' ? 'pln' : 'eur')
			}
		},
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		}),
	},
	watch: {
		currentUser(user, oldUser) {
			if (oldUser === null && user !== null) {
				this.setLang(user.lang)
			}
		}
	},
	mounted() {
		feather.replace();

		if (this.availableLocales.includes(this.cookies.get('lang'))) {
			this.locale = this.cookies.get('lang')
			this.changeCuurencyWhenNotSet(this.cookies.get('lang'))
		}
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="relative inline-block text-left mr-2 sm:mr-0">
		<div>
			<button
				type="button"
				class="focus:outline-none flex justify-center items-center"
				@click="langOpen = !langOpen"
				id="lang-button" 
				aria-expanded="true" 
				aria-haspopup="true">
				<i data-feather="globe"></i>
				<p class="font-sans ml-1">
					{{ locale.toUpperCase() }}
				</p>
			</button>
		</div>

		<div
			v-show="langOpen"
			@mouseleave="langOpen = false"
			class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-primary-side shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="lang-button"
			tabindex="-1"
		>
			<div class="py-1" role="none">
				<button @click="onSetLang('pl')" class="text-secondary-main block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Polski</button>
				<button @click="onSetLang('en')" class="text-secondary-main block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-0">English</button>
			</div>
		</div>
	</div>
</template>