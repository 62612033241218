import axios from 'axios'
import { defaultReqInterceptors, defaultResInterceptors, defaultResInterceptorsError } from '../main'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(config => defaultReqInterceptors(config))
axios.interceptors.response.use(
    response => defaultResInterceptors(response),
    error => defaultResInterceptorsError(error)
)

// AUTH
export function registration(data) {
    return axios.post('/auth.json', data)
}
export function sendPasswordResetLink(data) {
    return axios.post('/auth/password.json', data)
}
export function resetPassword(data) {
    return axios.put('/auth/password.json', data)
}
export function login(data) {
    return axios.post('/auth/sign_in.json', data)
}
export function logout() {
    return axios.delete('/auth/sign_out.json')
}
export function validateToken() {
    return axios.get('/auth/validate_token.json')
}
// export function updateUserData(data) {
//     return axios.put('/auth.json', data)
// }

// ADMIN

// PRODUCTS
export function createProduct(data) {
    return axios.post('/products.json', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
export function getProducts() {
    return axios.get('/products.json')
}
export function getProduct(id) {
    return axios.get('/products/' + id + '.json')
}
export function updateProduct(id, data) {
    return axios.put('/products/' + id + '.json', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
export function orderUpProduct(id) {
    return axios.put('/products/' + id + '/order_up.json')
}
export function orderDownProduct(id) {
    return axios.put('/products/' + id + '/order_down.json')
}
export function disableProduct(id) {
    return axios.put('/products/' + id + '/disable.json')
}
export function addProductImages(id, data) {
    return axios.post('/products/' + id + '/add_images.json', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}
export function removeProductImage(id) {
    return axios.post('/products/' + id + '/remove_image.json')
}
export function createProductPackage(id, data) {
    return axios.post('/products/' + id + '/add_package.json', data)
}
export function removeProductPackage(id) {
    return axios.post('/products/' + id + '/remove_package.json')
}
export function getAllProductInstructions() {
    return axios.get('/products/instructions.json')
}
export function createProductInstructions(id, data) {
    return axios.post('/products/' + id + '/add_instructions.json', data)
}
export function removeProductInstructions(id) {
    return axios.post('/products/' + id + '/remove_instructions.json')
}
export function toggleActiveProductInstructions(id) {
    return axios.put('/products/' + id + '/toggle_instruction_active.json')
}
export function getProductMinDateInfo(id) {
    return axios.get('/products/' + id + '/min_date.json')
}
// PAGES
export function getPages() {
    return axios.get('/pages.json')
}
export function createPage(data) {
    return axios.post('/pages.json', data)
}
export function getPage(id) {
    return axios.get('/pages/' + id + '.json')
}
export function updatePage(id, data) {
    return axios.put('/pages/' + id + '.json', data)
}
export function deletePage(id) {
    return axios.delete('/pages/' + id + '.json')
}

// ORDERS
export function createOrder(data) {
    return axios.post('/orders.json', data)
}
export function createOrderPackage(id, data) {
    return axios.post('/orders/' + id + '/add_package.json', data)
}

// Users
export function getUsers(filters) {
    return axios.get('/users.json', { params: filters })
}

export function getUser(id) {
    return axios.get('/users/' + id + '.json')
}

export function getUserRoles() {
    return axios.get('/users/get_roles.json')
}

export function setUserRole(id, role) {
    return axios.put('/users/' + id + '/set_role.json', { user: { role } })
}

export function updateUserEmail(id, data) {
    return axios.put('/users/' + id + '/update_email.json', { user: data })
}

export function updateUserPassword(id, data) {
    return axios.put('/users/' + id + '/update_password.json', { user: data })
}

export function logoutUser(id) {
    return axios.put('/users/' + id + '/logout_user.json')
}

export function updateUserData(id, data) {
    return axios.put('/users/' + id + '/update_data.json', { user: data })
}

// Promocodes
export function getPromocodes() {
    return axios.get('/promocodes.json')
}

export function createPromocode(data) {
    return axios.post('/promocodes.json', data)
}

export function disablePromocode(id) {
    return axios.put('/promocodes/' + id + '/disable.json')
}

export function redeemPromocode(id) {
    return axios.get('/promocodes/' + id + '/redeem.json')
}

// Payments
export function getPayments() {
    return axios.get('/payments.json')
}
export function getPayment(id) {
    return axios.get('/payments/' + id + '.json')
}
export function cancelPayment(id) {
    return axios.put('/payments/' + id + '/cancel.json')
}
export function adminPayPayment(id) {
    return axios.put('/payments/' + id + '/admin_pay_payment.json')
}

// Paypal
export function createPaypalOrder(id) {
    return axios.post('/payments/' + id + '/create_paypal.json')
}
export function capturePaypalOrder(id) {
    return axios.post('/payments/' + id + '/capture_paypal.json')
}