<template>
	<div v-if="currentUser" class="mt-10 sm:mt-0">
		<div class="overflow-hidden">
			<div class="bg-primary-side px-4 py-5 sm:p-6 grid grid-cols-6 gap-6">
				<div class="col-span-6">
					<h3 class="text-lg font-medium leading-6 text-ternary-main">
						{{ $t('cart.checkout.personal') }}
					</h3>
				</div>

				<div class="col-span-6">
					<label for="first_name" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.first_name') }}
					</label>
					<p v-if="currentUser.first_name" class="mt-1 text-lg text-secondary-main">{{ first_name }}</p>
					<input v-else type="text" name="first_name" id="first_name" autocomplete="given-name" v-model="first_name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
					
				</div>

				<div class="col-span-6">
					<label for="last_name" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.last_name') }}
					</label>
					<p v-if="currentUser.last_name" class="mt-1 text-lg text-secondary-main">{{ last_name }}</p>
					<input v-else type="text" name="last_name" id="last_name" autocomplete="family-name" v-model="last_name"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
				</div>

				<div class="col-span-6">
					<label for="email-address" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.email') }}
					</label>
					<p class="mt-1 text-lg text-secondary-main">{{ email }}</p>
				</div>

				<div class="col-span-6">
					<label for="address" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.address') }}
					</label>
					<p v-if="currentUser.address" class="mt-1 text-lg text-secondary-main">{{ address }}</p>
					<input v-else type="text" name="address" id="address" autocomplete="address" v-model="address"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
				</div>

				<div class="col-span-6">
					<label for="city" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.city') }}
					</label>
					<p v-if="currentUser.city" class="mt-1 text-lg text-secondary-main">{{ city }}</p>
					<input v-else type="text" name="city" id="city" autocomplete="city" v-model="city"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
				</div>

				<div class="col-span-6">
					<label for="postal" class="block text-sm font-medium text-secondary-main">
						{{ $t('cart.checkout.postal') }}
					</label>
					<p v-if="currentUser.postal" class="mt-1 text-lg text-secondary-main">{{ postal }}</p>
					<input v-else type="text" name="postal" id="postal" autocomplete="postal" v-model="postal"  class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
				</div>
			</div>

			<div class="bg-primary-side px-4 py-5 sm:p-6 grid grid-cols-6 gap-6 place-content-center">
				<div class="col-span-6">
					<h3 class="text-lg font-medium leading-6 text-ternary-main">
						{{ $t('cart.checkout.payment_method') }}
					</h3>
				</div>

				<div class="col-span-6 flex items-center justify-center">
					<div
						v-for="p in paymentMethods"
						:key="p"
						@click="selectedPayment = p"
						:class="[
							'w-full sm:w-1/4 sm:mx-2 shadow-md rounded-md border border-gray-300 p-2 hover:bg-red-600 text-secondary-main hover:text-white cursor-pointer',
							{'bg-red-600 text-white': selectedPayment == p}
						]"
					>
						{{ p }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="mt-10 sm:mt-0 text-center">
		<p class="mt-1 text-sm text-secondary-main">{{ $t('cart.loginToContinue') }}</p>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	data: () => ({
		email: '',
		first_name: '',
		last_name: '',
		address: '',
		city: '',
		postal: '',
		selectedPayment: 'Dotpay'
	}),
	computed: {
		...mapGetters({
			currency: 'currency',
			currentUser: 'user/currentUser',
		}),
		paymentMethods() {
			if (this.currency === 'pln') {
				return ['Dotpay', 'PayPal']
				// return ['Dotpay', 'PayPal', 'Coinbase']
			} else {
				return ['Dotpay', 'PayPal']
				// return ['Dotpay', 'PayPal', 'Coinbase']
			}
		}
	},
	methods: {
		...mapActions({
			setCartData: 'cart/setCartData',
		}),
		setUserData(user) {
			this.email = user.email
			this.first_name = user.first_name
			this.last_name = user.last_name
			this.address = user.address
			this.city = user.city
			this.postal = user.postal
		},
	},
	watch: {
		email(value) {
			this.setCartData({field: 'email', value: value}) 
		},
		first_name(value) {
			this.setCartData({field: 'first_name', value: value}) 
		},
		last_name(value) {
			this.setCartData({field: 'last_name', value: value}) 
		},
		address(value) {
			this.setCartData({field: 'address', value: value})
		},
		city(value) {
			this.setCartData({field: 'city', value: value})
		},
		postal(value) {
			this.setCartData({field: 'postal', value: value})
		},
		selectedPayment(value) {
			this.setCartData({field: 'selectedPayment', value: value}) 
		},
		currentUser(user) {
			if (user) this.setUserData(user)
		},
		currency(currency, oldCurrency) {
			if (currency !== oldCurrency) {
				this.selectedPayment = this.paymentMethods[0]
			}
		}
	},
	mounted() {
		if (this.currentUser) {
			this.setUserData(this.currentUser)
		}
	},
}
</script>