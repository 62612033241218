<script>
import feather from 'feather-icons';
import AppHeader from './components/shared/AppHeader';
import AppFooter from './components/shared/AppFooter';
import CartSlider from './components/cart/CartSlider.vue';
import CookieConsent from './components/shared/CookieConsent';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		AppHeader,
		AppFooter,
		CartSlider,
		CookieConsent,
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		}),
	},
	methods: {
		...mapActions({
			loadProducts: 'loadProducts',
			validateToken: 'user/validateToken',
		}),
		beforeMountGuards(meta, user, ipBanned = false) {
			if (ipBanned) {
				this.$router.push('/')
				return
			}
			// If user already logged in and try to go to onlyGuest route (mainly auth related)
			if (meta.onlyGuest && user !== null) {
				this.$router.push('/')
				return
			}
			// If guest try to go to authorize route
			if ((meta.authRequired || meta.onlyAdmin) && user === null) {
				this.$toast.info(this.$t('messages.mustLogin'))
				// TODO: ADD LOGIN PAGE
				// this.$router.push({ name: 'Login' })
				this.$router.push("/")
				console.log('AUTH NEEDED!!!')
				return
			}

			// If user try to go to onlyAdmin route
			if (meta.onlyAdmin && !user.is_admin) {
				this.$toast.error(this.$t('messages.accessDenied'))
				console.log('accessDenied!!!')
				this.$router.push('/')
			}
		},
	},
	beforeMount() {
		this.validateToken()
			.then(() => {
				this.beforeMountGuards(this.$route.meta, this.currentUser)
			})
			.catch(() => {
				this.beforeMountGuards(this.$route.meta, null)
			})
	},
	mounted() {
		this.loadProducts()
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div
		class="pt-0.5 h-min-screen bg-cover bg-no-repeat bg-fixed"
	>
		<AppHeader />

		<router-view />

		<back-to-top
			visibleoffset="500"
			right="30px"
			bottom="20px"
			class="shadow-lg"
		>
			<i data-feather="chevron-up"></i>
		</back-to-top>

		<CookieConsent />

		<CartSlider />

		<AppFooter />

	</div>
</template>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.h-min-screen {
	min-height: 100vh;
}

.vue-back-to-top {
	@apply p-2 bg-red-600 hover:bg-red-700 text-white transition
		duration-500
		ease-in-out
		transform
		hover:-translate-y-1 hover:scale-110;
	border-radius: 50%;
	font-size: 22px;
	line-height: 22px;
}

.fade-enter-active {
	animation: coming 0.4s;
	animation-delay: 0.2s;
	opacity: 0;
}

.fade-leave-active {
	animation: going 0.4s;
}

@keyframes going {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-10px);
		opacity: 0;
	}
}

@keyframes coming {
	from {
		transform: translateX(-10px);
		opacity: 0;
	}

	to {
		transform: translateX(0px);
		opacity: 1;
	}
}

.single-page {
	@apply text-left mt-10;
}
.single-page h1 {
	@apply font-semibold text-xl md:text-2xl xl:text-3xl text-ternary-main mb-5;
}
.single-page h2 {
	@apply font-semibold text-lg md:text-xl xl:text-2xl text-ternary-main mb-3;
}
.single-page h4 {
	@apply font-bold;
}
/* .single-page div {
	@apply mb-10 text-lg md:text-xl xl:text-2xl text-ternary-main whitespace-pre-line;
} */
.single-page p {
	@apply mb-2 text-ternary-main;
}
.single-page ol {
	@apply list-decimal list-outside pl-8;
}
.single-page ul {
	@apply list-disc list-outside pl-8;
}
.single-page li {
	@apply mb-2;
}
</style>
