<script>
import { trans } from '../../i18n/functions' 
import { formatLogoTitle, currencyPrice, currencySign } from '../../assets/js/helpers' 
import { mapGetters } from 'vuex';

export default {
	name: 'ProductSingle',
	props: ['product'],
	computed: {
		...mapGetters({
			currency: 'currency',
		}),
		translatedProduct() {
			return {
				title: trans(this.product, 'title', this.$i18n.locale),
				short: trans(this.product, 'short', this.$i18n.locale),
			}
		},
		sale() {
			const price = this.currency === 'eur' ? this.product.price_eur : this.product.price_pln
			const price_old = this.currency === 'eur' ? this.product.price_old_eur : this.product.price_old_pln

			if (!price_old) return false
			return Math.round((1 - price/price_old) * 100)
		}
	},
	methods: {
		formatLogoTitle,
		currencyPrice,
		currencySign,
	},
};
</script>

<template>
	<router-link
		:to="'/products/' + product.slug"
		class="product-single rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-side/80 border border-red-600 hover:border-red-700 duration-300 relative"
		aria-label="Single Product"
	>
		<div
			v-if="sale"
			class="
				absolute
				z-10
				left-full
				bottom-full
				translate-y-2/3
				-translate-x-2/3
			"
		>
			<span class="sale-sign">-{{ sale }}%</span>
		</div>
		<div class="
			aspect-w-16 aspect-h-9
			font-logo
			border-b border-red-600
			bg-red-600/30
			overflow-hidden 
			rounded-t-xl 
		">
			<div
				class="
					product-title
					flex items-center justify-center
					text-2xl italic
				" 
				v-html="formatLogoTitle(translatedProduct.title)"
			/>
		</div>
		<div class="text-center px-4 pt-4 pb-6">
			<p
				:class="['font-general-regular text-primary-main line-through text-base -mb-2', {
					'invisible': currencyPrice(product, 'price_old', currency) === 0
				}]"
			>
				{{ currencyPrice(product, 'price_old', currency) }} {{ currencySign(currency) }}
			</p>
			<p
				:class="[
					'font-general-regular mb-4 text-3xl font-bold',
					currencyPrice(product, 'price_old', currency) === 0 ? 'text-primary-main': 'text-green-600'
				]"
			>
				{{ currencyPrice(product, 'price', currency) }} {{ currencySign(currency) }}
			</p>
			<div class="font-general-regular text-primary-main whitespace-pre-line" v-html="translatedProduct.short" />
		</div>
	</router-link>
</template>

<style>
.product-single:hover .product-single__title {
	@apply bg-red-700;
}

.product-title span {
	@apply mx-1;
}

.product-title span:nth-child(odd) {
	@apply text-red-600;
}

</style>
