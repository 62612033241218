<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'cTradingTools',
		};
	},
};
</script>

<template>
	<div class="flex flex-col justify-center items-center text-center">
		<a
			href="mailto:contact@ctradingtools.com"
			class="font-general-regular text-base text-ternary-main/75 hover:text-red-600"
		>
			contact@ctradingtools.com
		</a>
		<img
			src="@/assets/images/dotpay.png"
			alt="Dotpay"
			class="h-6 my-5"
		/>
		<div
			class="font-general-regular text-lg text-ternary-main"
		>
			&copy; {{ copyrightDate }}.
			<router-link to="/" class="font-general-medium hover:underline hover:text-ternary-main duration-500">
				{{ projectName }}.
			</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
