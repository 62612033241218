<template>
	<div class="container mx-auto">
        <h1
            class="font-general-semibold text-3xl md:text-3xl xl:text-4xl mb-10 text-center sm:text-left text-ternary-main"
        >
            Panel administratora
        </h1>
    
        <router-view></router-view>
    </div>
</template>