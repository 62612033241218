export default {
    loading: 'Loading',
    nav: {
        products: 'Products',
        faq: 'About us',
        login: 'Login',
        signup: 'Sign up',
        logout: 'Logout',
    },
    forms: {
        email: 'Email',
        password: 'Password',
        password2: 'Repeat password',
        terms: 'I declare that I accept terms and conditions',
        save: 'Save',
        reqSend: 'Sent',
        resSuccess: 'Success',
    },
    list: {
        show: 'Show',
        edit: 'Edit',
        remove: 'Delete',
        empty: 'Empty',
    },
    loginModal: {
        toLogin: 'Already have an account? Login',
        toSignup: 'Don\'t have an account? Sign up',
    },
    banner: {
        header: 'We are providing tools for cTrader.com',
        content: 'We create proprietary tools and in cooperation with traders. If you are interested in placing an individual order for your own proprietary algorithm, please contact us!'
    },
    products: {
        list: {
            header: 'Our products',
            more: 'More products',
            admin: 'Products',
            manage_images: 'Manage images',
            manage_packages: 'Manage packages',
            manage_instructions: 'Manage instructions',
        },
        admin: {
            single: 'Product',
            new: 'Add product',
            title: 'Title',
            short: 'Short summary',
            description: 'Long description',
            price_pln: 'Current price in PLN', 
            price_old_pln: 'Old price in PLN (leave blank if no promotion)',
            price_eur: 'Current price in EUR', 
            price_old_eur: 'Old price in EUR (leave blank if no promotion)',
            images: 'Photos',
            add_images: 'Add more photos',
            package: 'Package',
            mail_title: 'Email title',
            mail_content: 'Email content',
            instructions_title: 'Instructions name',
            instructions: 'Instructions file',
            instructions_active: 'Instruction visible in the list of instructions',
        },
        messages: {
            success: 'The product was foamed correctly!',
            delete: 'Product deleted',
            changeOrder: 'The order of products has been changed',
        },
        errors: {
            title_en: {
                blank: 'Title in English cannot be empty',
            },
            short_en: {
                blank: 'English summary cannot be blank',
            },
            description_en: {
                blank: 'Description in English cannot be empty',
            },
            title_pl: {
                blank: 'Title in Polish cannot be empty',
            },
            short_pl: {
                blank: 'Summary in Polish cannot be empty',
            },
            description_pl: {
                blank: 'Description in Polish cannot be empty',
            },
            price_pln: {
                blank: 'Price in PLN cannot be empty',
            },
            price_eur: {
                blank: 'Price in EUR cannot be empty',
            },
            images: {
                blank: 'You must select a photo',
            },
            package: {
                blank: 'You need to attach a package',
            },
        },
        summary: 'Summary',
        price: 'Price',
        price_old: 'Regular price',
        price_new: 'New price',
        details: 'Details',
        other_products: 'Other products',
        lasts: 'One year access',
        vat: '(23% tax included)',
        packages: {
            list: '{product} packages',
            add: 'Add new package',
            starts_at: 'Active since',
            ends_at: 'Active until',
        },
        instructions: {
            list: '{product} instructions',
            add: 'Add new instruction',
            footer: 'Instructions',
            index: 'Product instructions',
            download: 'Download',
            empty: 'Instructions files coming soon',
            hide: 'Hide in the instructions page',
            activate: 'Display instructions on the page',
        },
    },
    product_images: {
        messages: {
            success: 'Photo added successfully!',
            delete: 'Photo deleted',
        },
    },
    orders: {
        starts: 'Active from: {date}',
        ends: 'Active to: {date}',
        package_update_at: 'Package updated at: {date}',
        download_package: 'Download package',
        no_package: 'No package attached',
        add_package: 'Add an updated package',
        messages: {
            add_package: 'A new package has been added to the order',
        }
    },
    pages: {
        list: {
            header: 'Pages list',
            admin: 'Pages',
        },
        admin: {
            new: 'Add page',
            title: 'Title',
            content: 'Content',
        },
        messages: {
            success: 'Page successfully saved!',
            delete: 'Page deleted',
        },
        errors: {
            title_en: {
                blank: 'Title in English cannot be empty',
            },
            content_en: {
                blank: 'Content in English cannot be empty',
            },
            title_pl: {
                blank: 'Title in Polish cannot be empty',
            },
            content_pl: {
                blank: 'Content in Polish cannot be empty',
            },
        },
    },
    cart: {
        add: 'Add to cart',
        remove: 'Remove from cart',
        messages: {
            addSuccess: 'Product added to cart!',
            remove: 'Product removed from cart',
            creatingOrder: 'Order is being placed',
            success: 'Order placed!',
        },
        steps: {
            cart: {
                header: 'Shopping cart',
                nextText: 'Checkout',
                backText: 'Continue Shopping',
            },
            checkout: {
                header: 'Order checkout',
                nextText: 'Pay for product',
                backText: 'Return to shopping cart',
            },
            payment: {
                header: 'Finalize payment',
                nextText: 'Go to order',
            },
        },
        loginToContinue: 'Login to continue',
        checkout: {
            personal: 'Personal Information',
            first_name: 'First name',
            last_name: 'Last name',
            email: 'Email',
            address: 'Address',
            address_short: 'Address',
            city: 'City',
            postal: 'Postal code',
            payment_method: 'Payment method',
        },
        payment: {
            header: 'Order completed',
            text1: 'You should be redirected to payment page shortly.',
            text2: 'If not go to order and finalize payment.',
        },
        total_price: 'Total price',
        promocode: {
            gotPromocode: 'I have a promo code',
            title: 'Add a promo code',
            promocode: 'Promo code',
            added: 'Promo code applied',
            removed: 'Promo code removed',
        }
    },
    users: {
        list: {
            header: 'User list',
            admin: 'Users',
            show: 'User',
            orders: 'Orders: {orders_count}',
            payments: 'Payments: {payments}',
        },
        account: {
            change_email: 'Change email',
            change_password: 'Change Password',
            change_data: 'Change personal information',
            logout: 'Log out from each device',
        },
        messages: {
            logout: 'Signed out from every device',
        },
        errors: {
            email: {
                blank: 'Email cannot be empty',
                taken: 'An account with the given email already exists',
            },
            password: {
                blank: 'Password cannot be blank',
                too_short: 'Password must be at least 6 characters long',
            },
            password_confirmation: {
                confirmation: 'You need to confirm your password',
            },
            terms: {
                accepted: 'You must accept the terms and conditions',
            },
        },
    },
    payments: {
        list: {
            header: 'Payment list',
            admin: 'Payments',
        },
        user: 'User: {name}',
        email: 'Email: {email}',
        header: 'Payment #{id}',
        date: 'Payment started: {date}',
        end_date: 'Payment ended: {date}',
        amount: 'Amount: {amount} {currency}',
        method: {
            dotpay: 'Payment method: Dotpay',
            paypal: 'Payment method: PayPal',
            coinbase: 'Payment methodCoinbase',
        },
        status: {
            to_pay: 'Status: To pay',
            done: 'Status: Done',
            error: 'Status: Payment error',
            admin_cancel: 'Status: Canceled by admin',
            user_cancel: 'Status: Canceled by user',
        },
        messages: {
            cancel: 'Payment canceled',
            adminPay: 'Marked as paid',
        },
        no_payment_url: 'Payment error. Contact the site administrator.',
        pay: 'Pay',
        cancel: 'Cancel payment',
        admin: {
            pay: 'Mark as paid',
        },
        payment_data: {
            dotpay: 'Payments data from Dotpay',
            paypal: 'Payments data from PayPal',
            coinbase: 'Payments data from inbase',
        },
    },
    promocodes: {
        list: {
            header: 'List of promo codes',
            admin: 'Promotional codes',
            useCount: 'Amount used: {count} ({percentage}%)',
            ends: 'Active until: {date}',
        },
        admin: {
            new: 'Add a promo code',
            code: 'Code (leave blank to generate automatically)',
            max_use_amount: 'Number of uses',
            percentage: 'Discount percentage',
            expires_at: 'End date',
        },
        errors: {
            code: {
                taken: 'A promo code with the given value already exists',
            },
            expires_at: {
                blank: 'Promo code expiration date cannot be empty',
            },
            percentage: {
                blank: 'The promo code discount percentage cannot be empty',
            },
            redeem: 'The promo code you entered does not exist or is no longer available',
        },
        maxUseOptions: {
            once: 'Once',
            once_per_user: 'One per user',
        },
        messages: {
            success: 'The promo code has been entered correctly!',
            delete: 'Promo code removed',
        },
    },
    cookies: {
        text: 'This website uses cookies to ensure you get the best experience on our website.',
        link: 'Learn more',
        allow: 'Allow Coockies',
    },
    footer: {
        pages: {
            privacy: 'Privacy Policy',
            terms: 'Terms and Conditions',
            contact: 'Contact form',
        },
    },
    messages: {
        saved: 'Saved',
        login: 'Login successful!',
        logout: 'Logout successful!',
        signup: 'Registration successful!',
        error: 'Error',
        mustLogin: 'You must log in',
        accessDenied: 'Access denied',
        onlyAdmin: 'Access for admin only',
        banned: 'You have been banned',
        fillAllFields: 'Fill all fields',
    },
    sayyes: 'Yes',
    sayno: 'No',
    close: 'Close',
    soon: 'Soon',
    pl: 'Polish',
    en: 'English',
    or: 'or',
    other: 'Other',
    mail: 'Email',
}