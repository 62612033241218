<template>
    <div v-if="show" class="max-w-screen-lg mx-auto fixed bg-primary-side inset-x-5 p-5 bottom-6 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between text-primary-main">
        <div class="w-full">
            {{ $t('cookies.text') }}
            <router-link :to="{name: 'SinglePage', params: { slug: 'cookies' }}" class="text-red-600 whitespace-nowrap hover:underline">{{ $t('cookies.link') }}</router-link>
        </div>
        <div class="flex gap-4 items-center flex-shrink-0">
            <button @click="cookies.set('acceptCookies', true), show = false" class="bg-red-600 px-5 py-2 text-white rounded-md hover:bg-red-700 focus:outline-none">{{ $t('cookies.allow') }}</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'

export default {
    setup() {
        const show = ref(true)
        const cookies = useCookies(['acceptCookies'])

        if (cookies.get('acceptCookies')) {
            show.value = false
        }
        return { show, cookies }
    },
}
</script>
