<template>
    <div class="sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 mb-10 justify-center items-center shadow-lg sm:shadow-none">
        <router-link
            :to="{name: 'Terms'}"
            class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2 "
        >
            {{ $t('footer.pages.terms') }}
        </router-link>
        <router-link
            :to="{name: 'Privacy'}"
            class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2 "
        >
            {{ $t('footer.pages.privacy') }}
        </router-link>
        <router-link
            :to="{name: 'Instructions'}"
            class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2 "
        >
            {{ $t('products.instructions.footer') }}
        </router-link>
        <router-link
            v-for="page in pages"
            :key="page.slug"
            :to="{name: 'SinglePage', params: { slug: page.slug }}"
            class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2 "
            :aria-label="trans(page, 'title', $i18n.locale)"
        >
            {{ trans(page, 'title', $i18n.locale) }}
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trans } from '../../i18n/functions'

export default {
    name: 'FooterPages',
    computed: {
        ...mapGetters({
            pages: 'pages',
        })
    },
    methods: {
        trans,
    }
}
</script>