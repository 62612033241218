<script>
import feather from 'feather-icons';
import { mapActions, mapGetters } from 'vuex';
import Cart from './Cart.vue';
import Checkout from './Checkout.vue';
import CartPromocode from './CartPromocode.vue';
import { createOrder } from '../../api/api'
import { currencySign } from '../../assets/js/helpers'

export default {
	name: 'CartSlider',
	components: {
		Cart,
		Checkout,
		CartPromocode,
	},
	data: () => ({
		stepId: 0,
		steps: [
			{
				id: 'cart',
			},
			{
				id: 'checkout',
			},
			{
				id: 'payment',
				noBackText: true,
			},
		],
		payment_id: null,
		formRequestSend: false,
	}),
	computed: {
		...mapGetters({
			orderData: 'cart/orderData',
			cartProducts: 'cart/cartProducts',
			cartTotalPrice: 'cart/cartTotalPrice',
			cartSliderOpen: 'cart/cartSliderOpen',
			canCreateOrder: 'cart/canCreateOrder',
			currentUser: 'user/currentUser',
			currency: 'currency',
		}),
		step() {
			return this.steps[this.stepId]
		},
	},
	methods: {
		currencySign,
		...mapActions({
			resetOrder: 'cart/resetOrder',
			toggleCartSlider: 'cart/toggleCartSlider',
			showAuthModal: 'user/showAuthModal',
		}),
		async onCreateOrder() {
			this.formRequestSend = false
			let success = false
			this.$toast.info(this.$t('cart.messages.creatingOrder'))

			await createOrder(this.orderData)
				.then(async (res) => {
					this.formRequestSend = false
					this.$toast.success(this.$t('cart.messages.success'))
					this.payment_id = res.data.payment_id
					if (res.data.payment_url) {
						this.resetCart()
						window.location.href = res.data.payment_url
					} else if (this.payment_id) {
						this.resetCart()
						this.$router.push({name: "UserPayment", params: { id: this.payment_id }})
					}
					success = true
				})
				.catch((error) => {
					this.formRequestSend = false
					this.$toast.error("PROBLEMY NAJMANA")
					console.log(error)
					if (error.response) {
						const errors = []
						Object.values(error.response.data.errors).forEach(err => {
							err.forEach(e => {
								errors.push(this.$t(e))
							})
						})
							this.$toast.error(errors.join('<br/>'))
					} else {
						this.$toast.error(this.$t('messages.fillAllFields'))
					}
					success = false
				})
			
			return success
		},
		resetCart() {
			this.toggleCartSlider()
			this.resetOrder()
			this.stepId = 0
		},
		async nextStep() {
			if (this.stepId == this.steps.length - 1) {
				this.resetCart()
				if (this.payment_id) {
					this.$router.push({name: "UserPayment", params: { id: this.payment_id }})
				} else {
					this.$router.push({name: 'MyAccount'})
				}
			} else if (this.step.id === 'checkout') {
				if (this.canCreateOrder) {
					if (await this.onCreateOrder()) {
						this.stepId = this.stepId + 1
					}
				} else {
					this.$toast.error(this.$t('messages.fillAllFields'))
				}
			} else {
				this.stepId = this.stepId + 1
			}
		},
		prevStep() {
			if (this.stepId == 0) {
				this.toggleCartSlider()
			} else {
				this.stepId = this.stepId - 1
			}
		},
	},
	watch: {
		currentUser(user) {
			if (!user) this.stepId = 0
		},
		cartSliderOpen() {
			if (!this.currentUser) this.stepId = 0
		},
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
		<div v-show="cartSliderOpen" class="relative z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
			<!-- Cart Slider body background as backdrop -->
			<div @click="toggleCartSlider()" class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>
			<!-- Cart Slider content -->
			<div class="pointer-events-none fixed inset-0 overflow-hidden z-30">
				<div class="pointer-events-none absolute inset-0 overflow-hidden">
					<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<transition name="slide-from-right">
							<div v-show="cartSliderOpen" class="pointer-events-auto w-screen max-w-md transform overflow-y-scroll">
								<div class="flex min-h-full flex-col bg-primary-side shadow-xl">
									<div class="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
										<div class="flex items-start justify-between">
											<h2 class="text-lg font-medium text-ternary-main" id="slide-over-title">
												{{ $t('cart.steps.' + step.id + '.header') }}
											</h2>
											<div class="ml-3 flex h-7 items-center">
												<button @click="toggleCartSlider()" type="button" class="-m-2 p-2 text-primary-main hover:text-secondary-main">
													<i data-feather="x"></i>
												</button>
											</div>
										</div>

										<Cart v-show="step.id == 'cart'" :cartProducts="cartProducts" />
										<Checkout v-show="step.id == 'checkout'" />
										<div
											v-show="step.id == 'payment'"
											class="h-full flex flex-col justify-center"
										>
											<h3 class="text-3xl font-medium leading-6 text-green-600">{{ $t('cart.payment.header') }}</h3>
											<div class="flex justify-center text-green-600 my-10">
												<i width="75px" height="75px" data-feather="check-circle"></i>
											</div>
											<p class="mt-1 text-sm text-secondary-main">{{ $t('cart.payment.text1') }}</p>
											<p class="mt-1 text-sm text-secondary-main">{{ $t('cart.payment.text2') }}</p>
										</div>

									</div>
									<div class="border-t border-gray-200 py-6 px-4 sm:px-6">
										<div class="flex justify-between text-base font-medium text-ternary-main">
											<p>{{ $t('cart.total_price') }}</p>
											<p>{{ cartTotalPrice }} {{ currencySign(currency) }}</p>
										</div>
										<CartPromocode v-if="currentUser" :cartStep="step" />
										<div class="mt-6">
											<button
												v-if="currentUser"
												@click="nextStep()"
												:disabled="formRequestSend"
												class="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 w-full"
											>
												{{ $t('cart.steps.' + step.id + '.nextText') }}
											</button>
											<button
												v-else
												@click="showAuthModal()"
												class="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700 w-full"
											>
												{{ $t('cart.loginToContinue') }}
											</button>

										</div>
										<div v-if="!step.noBackText" class="mt-6 flex justify-center text-center text-sm text-secondary-main">
											<p>
											{{ $t('or') }}
											<button @click="prevStep()" type="button" class="font-medium text-red-600 hover:text-red-500">
												{{ $t('cart.steps.' + step.id + '.backText') }}
												<span aria-hidden="true"> &rarr;</span>
											</button>
											</p>
										</div>
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
</template>

<style>
.slide-from-right-enter-from {
	transform: translateX(25%);
}
.slide-from-right-enter-to {
	transform: translateX(0%);
}
.slide-from-right-enter-active {
	animation: slide-from-right-coming 0.4s;
	animation-delay: 0.1s;
	opacity: 0;
}

@keyframes slide-from-right-coming {
	from {
		transform: translateX(25%);
		opacity: 0;
	}

	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
</style>