<script>
import feather from 'feather-icons';
import LoginModal from '../LoginModal.vue';
import AppHeaderLinks from './AppHeaderLinks.vue';
import CartButton from '../cart/CartButton.vue';
import LangButton from '../LangButton.vue';
import CurrencyButton from '../CurrencyButton.vue';

export default {
	components: {
		LoginModal,
		AppHeaderLinks,
		CartButton,
		LangButton,
		CurrencyButton,
	},
	data() {
		return {
			isOpen: false,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<nav id="nav" class="sm:container sm:mx-auto">
		<div
			class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6"
		>
			<div class="flex justify-between items-center px-4 sm:px-0">
				<div>
					<router-link to="/"
						><img
							src="@/assets/images/logo.png"
							class="w-36"
							alt="cTradingTools"
						/>
					</router-link>
				</div>

				<div class="sm:hidden flex justify-end items-center text-secondary-main">
					<button
						@click="isOpen = !isOpen"
						type="button"
						class="focus:outline-none mr-2"
						aria-label="Hamburger Menu"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-main"
						>
							<path
								v-if="isOpen"
								fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"
							></path>
							<path
								v-if="!isOpen"
								fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
							></path>
						</svg>
					</button>
					<LangButton />
					<CurrencyButton />
					<CartButton />
				</div>
			</div>

			<AppHeaderLinks :isOpen="isOpen" />
		</div>

		<LoginModal
			aria-modal="Login Modal"
		/>
	</nav>
</template>

<style scoped>
#nav a.router-link-exact-active {
	@apply font-medium;
}
</style>
