<script>
import feather from 'feather-icons';
import Button from '../reusable/Button.vue';
import CartButton from '../cart/CartButton.vue';
import LangButton from '../LangButton.vue';
import CurrencyButton from '../CurrencyButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	props: ['isOpen'],
	components: {
		Button,
		CartButton,
		LangButton,
		CurrencyButton,
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		})
	},
	methods: {
		...mapActions({
			logout: 'user/logout',
			showAuthModal: 'user/showAuthModal',
		}),
		onLogout () {
			this.logout()
				.then(() => {
					this.$toast.success(this.$t('messages.logout'))
				})
		}
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-end items-center shadow-lg sm:shadow-none text-secondary-main"
	>
		<template v-if="!currentUser">
			<div

				class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-side flex justify-start"
			>
				<Button
					:title="$t('nav.login')"
					class="text-md font-general-medium bg-red-600 hover:bg-red-700 text-white shadow-sm rounded-md px-5 py-2.5 duration-300 mb-2"
					@click="showAuthModal()"
					aria-label="Login Button"
				/>
			</div>
		</template>
		<template v-else>
			<router-link
				v-if="currentUser.is_admin"
				:to="{name: 'Admin'}"
				class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2"
				aria-label="Admin"
				>Admin</router-link
			>
			<router-link
				to="#"
				class="font-general-medium block text-left text-lg font-medium text-primary-main hover:text-ternary-main sm:mx-4 mb-2 sm:py-2 flex justify-center items-center">
				<i data-feather="user"></i>
				<router-link :to="{name: 'MyAccount'}" class="font-sans ml-1 text-ellipsis">
					{{ currentUser.email }}
				</router-link>
			</router-link>
			<button @click='onLogout' class="focus:outline-none flex justify-center items-center sm:mx-4 mb-2 sm:py-2">
				<i data-feather="log-out"></i>
			</button>
		</template>
		<div
			class="hidden border-t-2 pb-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-side sm:flex justify-start ml-4"
		>
			<LangButton />
		</div>
		<div
			class="hidden border-t-2 pb-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-side sm:flex justify-start ml-4"
		>
			<CurrencyButton />
		</div>
		<div
			class="hidden border-t-2 pb-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-side sm:flex justify-start ml-4"
		>
			<CartButton />
		</div>
	</div>
</template>