export default {
    loading: 'Ładowanie',
    nav: {
        products: 'Produkty',
        faq: 'O nas',
        login: 'Logowanie',
        signup: 'Rejestracja',
        logout: 'Wyloguj',
    },
    forms: {
        email: 'Email',
        password: 'Hasło',
        password2: 'Powtórz hasło',
        terms: 'Oświadczam, że akceptuję regulamin',
        save: 'Zapisz',
        reqSend: 'Wysłano',
        resSuccess: 'Sukces',
    },
    list: {
        show: 'Pokaż',
        edit: 'Edytuj',
        remove: 'Usuń',
        empty: 'Brak',
    },
    loginModal: {
        toLogin: 'Masz już konto? Zaloguj sie',
        toSignup: 'Nie masz konta? Zarejestruj się',
    },
    banner: {
        header: 'Dostarczamy narzędzia do cTrader.com',
        content: 'Tworzymy narzędzia autorskie oraz we współpracy z traderami. W przypadku zainteresowania złożenia zamówienia indywidualnego na swój autorski algorytm zapraszamy do kontaktu!'
    },
    products: {
        list: {
            header: 'Nasze produkty',
            more: 'Więcej produktów',
            admin: 'Produkty',
            manage_images: 'Zarządzaj zdjęciami',
            manage_packages: 'Zarządzaj paczkami',
            manage_instructions: 'Zarządzaj instrukcjami',
        },
        admin: {
            single: 'Produkt',
            new: 'Dodaj produkt',
            title: 'Tytuł',
            short: 'Krótkie podsumowanie',
            description: 'Dugi opis',
            price_pln: 'Aktualna cena w PLN', 
            price_old_pln: 'Stara cena w PLN (zostaw puste, jeżeli brak promocji)',
            price_eur: 'Aktualna cena w EUR', 
            price_old_eur: 'Stara cena w EUR (zostaw puste, jeżeli brak promocji)',
            images: 'Zdjęcia',
            add_images: 'Dodaj więcej zdjęć',
            package: 'Paczka',
            mail_title: 'Tytuł maila',
            mail_content: 'Treść maila',
            instructions_title: 'Nazwa instrukcji',
            instructions: 'Plik z instrukcjami',
            instructions_active: 'Instrukcja widoczna na liście instrukcji',
        },
        messages: {
            success: 'Poprawnie zapiano produkt!',
            delete: 'Usunieto produkt',
            changeOrder: 'Zmieniono kolejność produktów',
        },
        errors: {
            title_en: {
                blank: 'Tytuł po angielsku nie może być pusty',
            },
            short_en: {
                blank: 'Podsumowanie po angielsku nie może być pusta',
            },
            description_en: {
                blank: 'Opis po angielsku nie może być pusta',
            },
            title_pl: {
                blank: 'Tytuł po polsku nie może być pusty',
            },
            short_pl: {
                blank: 'Podsumowanie po polsku nie może być pusta',
            },
            description_pl: {
                blank: 'Opis po polsku nie może być pusta',
            },
            price_pln: {
                blank: 'Cena w PLN nie może być pusta',
            },
            price_eur: {
                blank: 'Cena w EUR nie może być pusta',
            },
            images: {
                blank: 'Musisz wybrać zdjęcie',
            },
            package: {
                blank: 'Trzeba załączyć paczkę',
            },
        },
        summary: 'Podsumowanie',
        price: 'Cena',
        price_old: 'Cena regularna',
        price_new: 'Nowa cena',
        details: 'Szczegóły',
        other_products: 'Inne produkty',
        lasts: 'Dostęp na rok',
        vat: '(w tym 23% VAT)',
        packages: {
            list: 'Paczki produktu {product}',
            add: 'Dodaj nową paczkę',
            starts_at: 'Aktywna od',
            ends_at: 'Aktywna do',
        },
        instructions: {
            list: 'Instrukcje produktu {product}',
            add: 'Dodaj nową instrukcję',
            footer: 'Instrukcje',
            index: 'Instrukcje produktów',
            download: 'Pobierz',
            empty: 'Instrukcje pojawią się wkrótce',
            hide: 'Ukryj na stronie z instrukcjami',
            activate: 'Wyświetlaj na stronie instrukcjami',
        },
    },
    product_images: {
        messages: {
            success: 'Poprawnie dodano zdjęcie!',
            delete: 'Usunieto zdjęcie',
        },
    },
    orders: {
        starts: 'Aktywny od: {date}',
        ends: 'Aktywny do: {date}',
        package_update_at: 'Paczka zaktualizowana: {date}',
        download_package: 'Pobierz paczkę',
        no_package: 'Brak dołączonej paczki',
        add_package: 'Dodaj zaktualizowaną paczkę',
        messages: {
            add_package: 'Dodano nową paczkę do zamówienia',
        }
    },
    pages: {
        list: {
            header: 'Lista stron',
            admin: 'Strony',
        },
        admin: {
            new: 'Dodaj stronę',
            title: 'Tytuł',
            content: 'Treść',
        },
        messages: {
            success: 'Poprawnie zapiano stronę!',
            delete: 'Usunieto stronę',
        },
        errors: {
            title_en: {
                blank: 'Tytuł po angielsku nie może być pusty',
            },
            content_en: {
                blank: 'Treść po angielsku nie może być pusta',
            },
            title_pl: {
                blank: 'Tytuł po polsku nie może być pusty',
            },
            content_pl: {
                blank: 'Treść po polsku nie może być pusta',
            },
        },
    },
    cart: {
        add: 'Dodaj do koszyka',
        remove: 'Usuń z koszyka',
        messages: {
            addSuccess: 'Produkt dodany do koszyka!',
            remove: 'Produkt usunięty z koszyka',
            creatingOrder: 'Składam zamówienie',
            success: 'Zamówienie złożone!',
        },
        steps: {
            cart: {
                header: 'Koszyk',
                nextText: 'Do podsumowania',
                backText: 'Kontynuuj zakupy',
            },
            checkout: {
                header: 'Podsumowanie',
                nextText: 'Zapłać za zamówienie',
                backText: 'Powrót do koszyka',
            },
            payment: {
                header: 'Zakończ płatność',
                nextText: 'Przejdź do zamówienia',
            },
        },
        loginToContinue: 'Zaloguj się, aby kontynuować',
        checkout: {
            personal: 'Dano osobowe',
            first_name: 'Imię',
            last_name: 'Nazwisko',
            email: 'Email',
            address: 'Adres (numer domu / numer lokalu)',
            address_short: 'Adres',
            city: 'Miasto',
            postal: 'Kodo pocztowy',
            payment_method: 'Sposób zapłaty',
        },
        payment: {
            header: 'Zamówienie złożone',
            text1: 'Wkrótce powinieneś zostać przekierowany na stronę płatności.',
            text2: 'Jeśli nie, przejdź do zamówienia i sfinalizuj płatność.',
        },
        total_price: 'Do zapłaty',
        promocode: {
            gotPromocode: 'Mam kod promocyjny',
            title: 'Dodaj kod promocyjny',
            promocode: 'Kod promocyjny',
            added: 'Zastosowano kod promocyjny',
            removed: 'Kod promocyjny usunięty',
        }
    },
    users: {
        list: {
            header: 'Lista użytkowników',
            admin: 'Użytkownicy',
            show: 'Użytkownik',
            orders: 'Zamówienia: {orders_count}',
            payments: 'Płatności: {payments}',
        },
        account: {
            change_email: 'Zmień email',
            change_password: 'Zmień hasło',
            change_data: 'Zmień dane osobowe',
            logout: 'Wyloguj z każdego urządzenia',
        },
        messages: {
            logout: 'Wylogowano z każdego urządzenia',
        },
        errors: {
            email: {
                blank: 'Email nie może być pusty',
                taken: 'Konto z podanym emailem już istnieje',
            },
            password: {
                blank: 'Hasło nie może być pusta',
                too_short: 'Hasło musi mieć minimum 6 znaków',
            },
            password_confirmation: {
                confirmation: 'Musisz potwierdzić hasło',
            },
            terms: {
                accepted: 'Musisz zaakceptować regulamin',
            },
        },
    },
    payments: {
        list: {
            header: 'Lista płatności',
            admin: 'Płatności',
        },
        user: 'Użytkownik: {name}',
        email: 'Email: {email}',
        header: 'Płatność #{id}',
        date: 'Płatność rozpoczęta: {date}',
        end_date: 'Płatność zakończona: {date}',
        amount: 'Kwota: {amount} {currency}',
        method: {
            dotpay: 'Metoda płatności: Dotpay',
            paypal: 'Metoda płatności: PayPal',
            coinbase: 'Metoda płatności: Coinbase',
        },
        status: {
            to_pay: 'Status: Do zapłaty',
            done: 'Status: Zakończona',
            error: 'Status: Błąd płatności',
            admin_cancel: 'Status: Anulowana przez admina',
            user_cancel: 'Status: Anulowana przez użytkownika',
        },
        messages: {
            cancel: 'Anulowano wpłatę',
            adminPay: 'Oznaczono jako opłacone',
        },
        no_payment_url: 'Błąd płatności. Skontaktuj się z administratorem strony.',
        pay: 'Opłać',
        cancel: 'Anuluj płatność',
        admin: {
            pay: 'Oznacz jako opłacone',
        },
        payment_data: {
            dotpay: 'Dane płatności z Dotpay',
            paypal: 'Dane płatności z PayPal',
            coinbase: 'Dane płatności z Coinbase',
        },
    },
    promocodes: {
        list: {
            header: 'Lista kodów promocyjnych',
            admin: 'Kody promocyjne',
            useCount: 'Ilość użyć: {count} ({percentage}%)',
            ends: 'Aktywny do: {date}',
        },
        admin: {
            new: 'Dodaj kod promocyjny',
            code: 'Kod (zostaw pusty, żeby wygenerować automatycznie)',
            max_use_amount: 'Ilość użyć',
            percentage: 'Procent zniżki',
            expires_at: 'Data zakończenia',
        },
        errors: {
            code: {
                taken: 'Kod promocyjny z podaną wartością już istnieje',
            },
            expires_at: {
                blank: 'Data zakończenia kodu promocyjnego nie może być pusta',
            },
            percentage: {
                blank: 'Procent zniżki kodu promocyjnego nie może być pusty',
            },
            redeem: 'Podany kod promocyjny nie istnieje lub nie jest już dostępny',
        },
        maxUseOptions: {
            once: 'Jednorazowy',
            once_per_user: 'Jeden na użytkownika',
        },
        messages: {
            success: 'Poprawnie zapiano kod promocyjny!',
            delete: 'Usunieto kod promocyjny',
        },
    },
    cookies: {
        text: 'Ta strona korzysta z plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny.',
        link: 'Dowiedz się więcej',
        allow: 'Zezwalaj na pliki cookie',
    },
    footer: {
        pages: {
            privacy: 'Polityka Prywatności',
            terms: 'Regulamin',
            contact: 'Formularz kontaktowy',
        },
    },
    messages: {
        saved: 'Zapisano',
        login: 'Zalogowano pomyślnie!',
        logout: 'Wylogowano pomyślnie!',
        signup: 'Konto założone pomyślnie!',
        error: 'Błąd',
        mustLogin: 'Musisz się zalogować',
        accessDenied: 'Brak dostępu',
        onlyAdmin: 'Dostęp tylko dla admina',
        banned: 'Zostałeś zbanowany',
        fillAllFields: 'Wypełnij wszystkie pola',
    },
    sayyes: 'Tak',
    sayno: 'Nie',
    close: 'Zamknij',
    soon: 'Wkrótce',
    pl: 'Polski',
    en: 'Angielski',
    or: 'albo',
    other: 'Inne',
    mail: 'Poczta',
}