<script>
import FooterPages from '../footer/FooterPages.vue';
import FooterCopyright from '../footer/FooterCopyright.vue';
export default {
	components: { 
		FooterPages,
		FooterCopyright,
	},
};
</script>

<template>
	<div class="container mx-auto ">
		<div
			class="pt-10 sm:pt-15 pb-8 mt-20 border-t-2 border-primary-side"
		>
			<FooterPages />
			<FooterCopyright />
		</div>
	</div>
</template>

<style scoped></style>
