<script>
import { mapActions, mapGetters } from 'vuex'
import { trans } from '../../i18n/functions'
import { currencyPrice, currencySign } from '../../assets/js/helpers'

export default {
	props: ['cartProducts'],
	computed: {
		...mapGetters({
			currency: 'currency',
		}),
	},
	methods: {
		currencyPrice,
		currencySign,
		trans,
		...mapActions({
            toggleProductInCart: 'cart/toggleProductInCart',
		}),
		onToggleProductInCart(id) {
			this.toggleProductInCart(id)
			this.$toast.info(this.$t('cart.messages.remove'))
		},
	}
}
</script>

<template>
	<div class="mt-8">
		<div class="flow-root">
			<ul role="list" class="-my-6 divide-y divide-gray-200">
				<li
					v-for="product in cartProducts"
					:key="'cartProduct' + product.id"
					class="flex py-6"
				>
					<div class="ml-4 flex flex-1 flex-col">
						<div>
							<div class="flex justify-between text-base font-medium text-ternary-main">
								<h3>
									<a :href="'/products/' + product.slug">{{ trans(product, 'title', $i18n.locale) }}</a>
								</h3>
								<p class="ml-4">{{ currencyPrice(product, 'price', currency) }} {{ currencySign(currency) }}</p>
							</div>
						</div>
						<div class="flex flex-1 items-end justify-end text-sm">
							<div class="flex">
								<button @click="onToggleProductInCart(product.id)" type="button" class="font-medium text-red-600 hover:text-red-500">{{ $t('list.remove') }}</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>