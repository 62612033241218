<script>
import feather from 'feather-icons';
import Button from '../reusable/Button.vue';
import { redeemPromocode } from '../../api/api'
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['cartStep'],
    components: { Button },
    data() {
        return {
            modal: false,
            promocode: '',
            reqSend: false,
            resSuccess: false,
        };
    },
    computed: {
        ...mapGetters({
            getPromocode: 'cart/promocode',
        })
    },
    methods: {
        ...mapActions({
            setPromocode: 'cart/setPromocode',
        }),
        showModal() {
            this.modal = !this.modal
        },
        submitForm() {
            this.reqSend = true

            redeemPromocode(this.promocode).then((res) => {
                this.reqSend = false
                this.resSuccess = true
                this.$toast.success(this.$t('cart.promocode.added'))
                console.log(res.data.code, res.data.percentage)
                this.setPromocode(res.data)
                this.showModal()
                this.resetData()
            }).catch(error => {
                this.reqSend = false
                this.resSuccess = false
                if (error.response && error.response.data.errors) {
                    const errors = []
                    Object.values(error.response.data.errors).forEach(err => {
                        err.forEach(e => {
                            errors.push(this.$t(e))
                        })
                    })
                    this.$toast.error(errors.join('<br/>'))
                } else {
                    this.$toast.error(this.$t('messages.error'))
                }
            })
        },
        resetData() {
            this.promocode = ''
        },
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
};
</script>

<template>
    <div class="mt-3 text-left text-sm text-secondary-main">
        <button v-if="!getPromocode" @click="showModal()" class="text-red-600 hover:text-red-500">
            {{ $t('cart.promocode.gotPromocode') }}
        </button>
        <template v-else>
            <div class="flex justify-between text-ternary-main">
                <p>{{ $t('cart.promocode.promocode') }}: {{ getPromocode.code }}</p>
                <p>{{ getPromocode.percentage }}%</p>
            </div>
            <button v-if="!cartStep.noBackText" @click="setPromocode(null)" class="text-red-600 hover:text-red-500">
                {{ $t('list.remove') }}
            </button>
        </template>

        <transition name="fade">
            <div v-show="modal" class="font-general-regular fixed inset-0 z-30">
                <!-- Modal body background as backdrop -->
                <div
                    v-show="modal"
                    @click="showModal()"
                    class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"
                ></div>
                <!-- Modal content -->
                <main
                    class="flex flex-col items-center justify-center h-full w-full"
                >
                    <transition name="fade-up-down">
                        <div
                            v-show="modal"
                            class="modal-wrapper flex items-center z-30"
                        >
                            <div
                                class="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-primary-side max-h-screen shadow-lg flex-row rounded-lg relative"
                            >
                                <div
                                    class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-side"
                                >
                                    <h5
                                        class="text-primary-main text-xl"
                                    >
                                        {{ $t('cart.promocode.title') }}
                                    </h5>
                                    <button
                                        class="px-4 text-primary-main"
                                        @click="showModal()"
                                    >
                                        <i data-feather="x"></i>
                                    </button>
                                </div>
                                <div class="modal-body p-5 w-full h-full">
                                    <div class="max-w-xl m-4 text-left">
                                        <div class="mb-2">
                                            <input
                                                v-model="promocode"
                                                class="w-full px-5 py-2 border-1 border-gray-200 rounded-md text-md text-primary-side"
                                                id="promocode"
                                                name="promocode"
                                                type="text"
                                                required=""
                                                :placeholder="$t('cart.promocode.promocode')"
                                            />
                                        </div>
                                        <div class="mt-6 pb-4 sm:pb-1 flex justify-between">
                                            <button
                                                @click="submitForm"
                                                class="px-4
                                                sm:px-6
                                                py-2
                                                sm:py-2.5
                                                text-white
                                                bg-red-600
                                                hover:bg-red-700
                                                rounded-md
                                                focus:ring-1 focus:ring-red-900 duration-500"
                                            >
                                                {{ $t('forms.save') }}
                                            </button>
                                            <Button
                                                :title="$t('close')"
                                                class="px-4
                                                sm:px-6
                                                py-2
                                                sm:py-2.5
                                                text-white
                                                bg-gray-600
                                                hover:bg-ternary-main
                                                rounded-md
                                                focus:ring-1 focus:ring-red-900 duration-500
                                                ml-2"
                                                @click="showModal()"
                                                aria-label="Close Modal"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </main>
            </div>
        </transition>
    </div>
</template>