<script>
import feather from 'feather-icons';
import { mapGetters } from 'vuex';
import ProductSingle from './ProductSingle.vue';

const BREAKPOINT = 1024

export default {
	name: 'ProductsGrid',
	components: { ProductSingle },
	data: () => ({
		windowWidth: window.innerWidth,
		mainProdIndex: 0,
	}),
	computed: {
		...mapGetters({
			products: 'products',
		}),
		showGalleryArrows() {
			return this.products.length > 3 || this.windowWidth < BREAKPOINT
		},
		displayedProducts() {
			let prods = []

			if (this.windowWidth < BREAKPOINT) {
				prods.push(this.products[this.mainProdIndex])
			} else {
				if (this.products.length <= 3) {
					prods = this.products
				} else {
					let sidx = this.mainProdIndex
					for(var i=0; i<3; i++) {
						prods.push(this.products[sidx])
						sidx += 1
						if (sidx >= this.products.length) {
							sidx = 0
						}
					}
				}
			}
			return prods
		},
		canPrevProd() {
			return this.mainProdIndex > 0
		},
		canNextProd() {
			return this.mainProdIndex + (this.windowWidth < BREAKPOINT ? 0 : 2) < this.products.length - 1
		},
	},
	methods: {  
		onResize() {
			this.windowWidth = window.innerWidth
			if (this.windowWidth >= BREAKPOINT && this.products.length <= 3) {
				this.mainProdIndex = 0
			}
		},
		prevProd() {
			if (!this.canPrevProd) return

			this.mainProdIndex -= 1
		},
		nextProd() {
			if (!this.canNextProd) return

			this.mainProdIndex += 1
		},
	},
	mounted() {
        feather.replace();
		this.$nextTick(() => window.addEventListener('resize', this.onResize))
	},
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},
    updated() {
        feather.replace();
    },
};
</script>

<template>
	<section class="pt-10 sm:pt-14 px-12 sm:px-0">
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-12 text-ternary-main"
			>
				{{ $t('products.list.header') }}
			</p>
		</div>

		<div
			class="relative grid grid-cols-1 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<button
				:class="[
					'absolute right-full h-full px-4 text-primary-main text-xl',
					{ 'invisible': !canPrevProd }
				]"
				@click="prevProd"
			>
				<i data-feather="chevron-left"></i>
			</button>
			<button
				:class="[
					'absolute left-full h-full px-4 text-primary-main text-xl',
					{ 'invisible': !canNextProd }
				]"
				@click="nextProd"
			>
				<i data-feather="chevron-right"></i>
			</button>
			
			<ProductSingle
				v-for="product in displayedProducts"
				:key="product.id"
				:product="product"
			/>
		</div>
	</section>
</template>

<style scoped></style>
