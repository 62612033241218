import { createStore } from 'vuex'
import { getProducts, getPages } from '../api/api'

import user from './user'
import cart from './cart'

export default createStore({
    state: {
        pageLoad: false,
        serverOffline: process.env.VUE_APP_IS_OFFLINE == "true",
        isProductsLoad: false,
        products: [],
        pages: [],
        currency: 'pln',
    },
    getters: {
        pageLoad: state => state.pageLoad,
        serverOffline: state => state.serverOffline,
        isProductsLoad: state => state.isProductsLoad,
        products: state => state.products,
        pages: state => state.pages,
        currency: state => state.currency,
    },
    mutations: {
        pageLoad(state) {
            state.pageLoad = true
        },
        serverOffline(state, payload) {
            state.serverOffline = payload
        },
        loadProducts(state, payload) {
            state.products = payload
            state.isProductsLoad = true
        },
        loadPages(state, payload) {
            state.pages = payload
        },
        setCurrency(state, payload) {
            state.currency = payload
        },
    },
    actions: {
        pageLoad({ commit }) {
            commit('pageLoad')
        },
        serverOffline({ commit }) {
            commit('serverOffline', true)
        },
        setCurrency({ commit }, payload) {
            commit('setCurrency', payload)
        },
        loadProducts({ commit, state }) {
            state.isProductsLoad = false
            return getProducts()
                .then(res => {
                    commit('loadProducts', res.data.products)
                })
        },
        loadPages({ commit, state }, payload) {
            if (payload === null) {
                state.pageLoad = true
                return getPages()
                    .then(res => {
                        commit('loadPages', res.data.pages)
                        commit('pageLoad')
                    })
            } else {
                commit('loadPages', payload)
            }
        },
    },
    modules: {
        user,
        cart,
    }
})